import React, { useEffect, useState } from "react";
import "./App.css";
import "./App-dark.css";
import { FaXTwitter } from "react-icons/fa6";

import Auris from "./Images/HomepageIcons/Auris.png";
import ComputerTalk from "./Images/HomepageIcons/ComputerTalk.png";
// import vocalreply from "./Images/HomepageIcons/DriveLog.png";
import Encyclopedia from "./Images/HomepageIcons/Encyclopedia.png";
import EmojiGuessingGame from "./Images/HomepageIcons/EmojiGuessingGame.png";
import ImageResizer from "./Images/HomepageIcons/ImageResizer.png";
import JobAdvisor from "./Images/HomepageIcons/JobAdvisor.png";
import NameGenerator from "./Images/HomepageIcons/NameGenerator.png";
import Notaduo from "./Images/HomepageIcons/Notaduo.png";
import PWGenerator from "./Images/HomepageIcons/PWGenerator.png";
import QrGenerator from "./Images/HomepageIcons/QrGenerator.png";
// import ReferMe from "./Images/HomepageIcons/ReferMe.png"
// import SumaNews from "./Images/HomepageIcons/SumaNews.png";
import TextCounter from "./Images/HomepageIcons/TextCounter.png";

// import PaperAirplane from "./Images/PaperAirplane.png";

function App() {
	const isTouchDevice = "ontouchstart" in window;
	const [imageUrls, setImageUrls] = useState([
		{ url: ComputerTalk, link: "https://computertalk.netlify.app", text: "Computer Talk", overlayShown: false },
		{ url: Encyclopedia, link: "https://encyclopedia.one/", text: "Encyclopedia", overlayShown: false },
		{
			url: EmojiGuessingGame,
			link: "https://emojiguessinggame.netlify.app",
			text: "Guessing Game",
			overlayShown: false,
		},

		{ url: JobAdvisor, link: "https://jobadvisor.netlify.app/", text: "Job Advisor", overlayShown: false },
		{ url: Auris, link: "https://auriseartraining.com/", text: "Auris Ear Training", overlayShown: false },
		{ url: Notaduo, link: "https://notaduo.com/", text: "Notaduo", overlayShown: false },

		{ url: NameGenerator, link: "https://anynamegenerator.net/", text: "Name Generator", overlayShown: false },
		{ url: ImageResizer, link: "https://imageresizertool.netlify.app", text: "Image Resizer", overlayShown: false },
		// { url: ReferMe, link: "https://referme.nl/", text: "Refer Me", overlayShown: false },

		{ url: QrGenerator, link: "https://qrgeneratortool.netlify.app", text: "QR Generator", overlayShown: false },
		{
			url: PWGenerator,
			link: "https://pwgeneratortool.netlify.app",
			text: "Password Generator",
			overlayShown: false,
		},
		// { url: SumaNews, link: "https://sumanews.netlify.app", text: "Suma News", overlayShown: false },
		{ url: TextCounter, link: "https://textcountertool.netlify.app", text: "Text Counter", overlayShown: false },
	]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 9;
	const totalPages = Math.ceil(imageUrls.length / itemsPerPage);
	const currentImages = imageUrls.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
	const [email, setEmail] = useState("");
	const [subscriptionStatus, setSubscriptionStatus] = useState("");
	const [showSubscribeForm, setShowSubscribeForm] = useState(false);
	const [isExiting, setIsExiting] = useState(false);
	const [isStatusExiting, setIsStatusExiting] = useState(false);

	const BACKEND_URL = "https://octoocto-backend.vercel.app";

	const handlePageChange = (direction) => {
		if (direction === ">") {
			setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
		} else if (direction === "<") {
			setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
		}
	};

	useEffect(() => {
		const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
		const handleDarkModeChange = (e) => {
			document.body.classList.toggle("dark", e.matches);
		};
		darkModeMediaQuery.addEventListener("change", handleDarkModeChange);

		handleDarkModeChange(darkModeMediaQuery);

		return () => {
			darkModeMediaQuery.removeEventListener("change", handleDarkModeChange);
		};
	}, []);

	const handleImageClick = (e, image, index) => {
		e.preventDefault();
		const absoluteIndex = (currentPage - 1) * itemsPerPage + index;
		const updatedImages = imageUrls.map((img, idx) => {
			if (idx === absoluteIndex) {
				return { ...img, overlayShown: !img.overlayShown };
			}
			return img;
		});

		setImageUrls(updatedImages);

		if (image.overlayShown) {
			window.location.href = image.link;
		}
	};

	const handleMouseEnter = (pageRelativeIndex) => {
		if (!isTouchDevice) {
			const absoluteIndex = (currentPage - 1) * itemsPerPage + pageRelativeIndex;
			setImageUrls((prevImageUrls) =>
				prevImageUrls.map((img, idx) => (idx === absoluteIndex ? { ...img, overlayShown: true } : img))
			);
		}
	};

	const handleMouseLeave = (pageRelativeIndex) => {
		if (!isTouchDevice) {
			const absoluteIndex = (currentPage - 1) * itemsPerPage + pageRelativeIndex;
			setImageUrls((prevImageUrls) =>
				prevImageUrls.map((img, idx) => (idx === absoluteIndex ? { ...img, overlayShown: false } : img))
			);
		}
	};

	const handleSubscribe = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch(`${BACKEND_URL}/subscribe`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email }),
			});

			if (!response.ok) {
				throw new Error("Subscription failed");
			}

			setIsExiting(true);

			// First, let the form exit animation complete
			setTimeout(() => {
				setShowSubscribeForm(false);
				setIsExiting(false);
				// Then show the success message
				setSubscriptionStatus("Signup successful!");
				setEmail("");

				// Finally, handle the success message exit
				setTimeout(() => {
					setIsStatusExiting(true);
					setTimeout(() => {
						setSubscriptionStatus("");
						setIsStatusExiting(false);
					}, 400);
				}, 1500);
			}, 400);
		} catch (error) {
			console.error("Subscription error:", error);
			setSubscriptionStatus("Subscription failed. Please try again.");
			setTimeout(() => {
				setSubscriptionStatus("");
			}, 3000);
		}
	};

	return (
		<div className="App">
			<header>
				<br />
				<br />
				<h1>OCTO OCTO</h1>
			</header>
			<main>
				<div>
					<br />
					{/* <br /> */}
					<div className="image-container">
						{currentImages.map((image, index) => (
							<div
								key={index}
								onMouseEnter={() => (isTouchDevice ? null : handleMouseEnter(index))}
								onMouseLeave={() => (isTouchDevice ? null : handleMouseLeave(index))}
							>
								<a
									href={image.link}
									onClick={(e) => handleImageClick(e, image, index)}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={image.url} alt={image.text} />
									{image.overlayShown && (
										<div className="overlay">
											<span className="overlay-text">{image.text}</span>
										</div>
									)}
								</a>
							</div>
						))}
					</div>

					<br />

					{currentPage > 1 && (
						<button className="pagination-button" onClick={() => handlePageChange("<")}>
							{"←"}
						</button>
					)}
					{currentPage < totalPages && (
						<button className="pagination-button" onClick={() => handlePageChange(">")}>
							{"→"}
						</button>
					)}
				</div>

				<div className="mailing-list-container">
					{!showSubscribeForm ? (
						<button className="mailing-list-button" onClick={() => setShowSubscribeForm(true)}>
							Stay Tuned
						</button>
					) : (
						<form onSubmit={handleSubscribe} className={isExiting ? "form-exit" : ""}>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Enter your email"
								required
								maxLength={100}
								className="mailing-list-input"
							/>
							<button type="submit" className="mailing-list-button">
								Subscribe
							</button>
						</form>
					)}
					{subscriptionStatus && (
						<p className={`subscription-status ${isStatusExiting ? "status-exit" : ""}`}>
							{subscriptionStatus}
						</p>
					)}
				</div>

				<div className="octo-octo-logo">
					{/* <a href="mailto:info@auriseartraining.com?subject=Hello&body=Message">
						<img src={PaperAirplane} alt="contact"></img>
					</a> */}
					<a href="https://twitter.com/0cto0cto" target="_blank" rel="noopener noreferrer">
						<FaXTwitter size={24} style={{ color: "var(--text-color)" }} />
					</a>
				</div>
			</main>
		</div>
	);
}

export default App;
